<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="selectedVerifier !== null">
    <v-card-title class="card-header fixed-card-title" >
      <v-row class="justify-end d-flex" dense>
        <!--        <v-col cols="8">-->
        <!--          &lt;!&ndash; <v-toolbar-title >{{$t('verifier')}}</v-toolbar-title> &ndash;&gt;-->
        <!--        </v-col>-->
        {{$t('View-Verifier')}}
        <v-spacer></v-spacer>
        <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
        <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-10">
      <v-container>
        <v-row dense class="my-div my-5 px-5 pb-3">
          <v-col cols=12>
            <span class="verifierView-custom">{{$t('domainname')}}: {{ selectedVerifier.rvDomain }}</span>
          </v-col>
          <v-col cols=12>
            <span class="verifierView-custom2">{{$t('lowvalue')}}:  {{ selectedVerifier.lowValue }}</span>
          </v-col>
          <v-col cols=12>
            <span class="verifierView-span"><b>{{$t('shortdescription')}}:</b> {{ selectedVerifier.shortDescription }}</span>
          </v-col>
          <v-col cols=12>
            <span class="verifierView-span"><b>{{$t('shortdescriptionesp')}}:</b> {{ selectedVerifier.shortDescriptionEsp }}</span>
          </v-col>
          <v-col cols=12>
            <span class="verifierView-span"><b>{{$t('longdescription')}}:</b> {{ selectedVerifier.longDescription }}</span>
          </v-col>
          <v-col cols=12>
            <span class="verifierView-span"><b>{{$t('longdescriptionesp')}}:</b> {{ selectedVerifier.longDescriptionEsp }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardCoreAppBar',
  components: {},
  data: () => ({
    // valid: true,
  }),
  async mounted () {
  },
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
  computed: {
    ...mapGetters({
      selectedVerifier: 'verifiers/getSelectedVerifier',
    }),
  },
  methods: {
    closeDrawer () {
      this.$store.commit('verifiers/SELECTED_VERIFIER'); // set selectedVerifier = null
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
    syncronizeDrawers () {
      // open drawer for edit
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'verifier',
      });
    },
  },
};
</script>
