<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="userData !== null && userRole !== ''"
  >
    <v-card-title
        class="card-header-add fixed-card-title"
    >
      <v-row dense>
        <v-col class="d-flex align-center justify-start" cols="10">
          <span
              class="mr-2 font-size15"
              :class="getUserById.status === 1 ? 'color: #FFF; font-weight: bold' : 'color: #FFF; font-weight: bold; font-size: 0.8em'"
          >
            <v-icon small :style="getUserById.status === 1 ? 'color: lime; font-weight: bold' : 'color: gray; font-weight: bold'" class="me-2">mdi-checkbox-blank-circle</v-icon>
            {{ getUserById.status === 1 ? $t('active-caps') : $t('inactive-caps') }}
          </span>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-icon @click="sinhronizeDrawers" color="white" class="mr-2">mdi-pencil</v-icon>
          <v-icon color="white" @click="closeEditDrowerDetails">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-container class="mt-16">
      <v-row class="py-2 ">
        <v-col cols="12" class="text-left mx-0" >
          <div class="ml-3">
            <span class="d-flex font-weight-bold font-size18">{{ userData.firstName }} {{ userData.lastName }}</span>
            <span>
              <img height="18px" :src="userData.roleId === 3 ? doctorBlack : nurseBlack" >
              {{ this.$i18n.locale === 'en' ? userData.roleData.roleNameForDisplay : userData.roleData.roleNameForDisplayESP }}
            </span>
            <span class="d-flex">
              <v-icon small color="primary">mdi-email</v-icon>
              <span class="color-primary ml-1">{{ userData.email }}</span>
            </span>
            <span class="d-flex">
              <v-icon small color="primary" class="mr-1">mdi-phone</v-icon>
              <span class="color-primary"> {{ phone(userData.phone) }}</span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row
          dense
          v-if="userData.roleId === 3"
          class="mx-0 my-6 pa-2 background-gray-color data-table-outline"
      >
        <span class="staffView-specializations">{{$t('specializations')}}</span>
        <v-col cols="12" class="mt-0 py-1 pl-4">
          <v-chip-group column v-if="userData?.doctorMetadataReadDto && this.$i18n.locale === 'en'">
            <v-chip small label color="chips" class="ma-1 mr-1 text-color-white"  v-for="spec in userData.doctorMetadataReadDto?.spetializationData" :key="spec.spetializationId">{{ spec.spetializationDesc }}
            </v-chip>
          </v-chip-group>
          <v-chip-group column  v-else>
            <v-chip small label color="primary2" class="ma-0 mr-1" v-for="spec in userData.doctorMetadataReadDto?.spetializationData" :key="spec.spetializationId">{{ spec.spetializationDescEsp }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row v-if="userData.hospitalsAssignedData.length === 1" dense class="mx-0 my-6 pa-2 background-gray-color">
        <v-col cols="2" v-if="roleName === 'ORGANIZATION_ADMIN'" class="pl-0">
          <v-btn
              v-if="roleName === 'ORGANIZATION_ADMIN'"
              x-small
              elevation="0"
              color="secondary staffView-office-details"
              @click="showDialogAssignHospitalToUser"
          >
            <v-icon small color="primary">mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="pl-0">
          <span class="staffView-office-title mb-4">{{$t("officeInfo")}}</span>
          <span class="staffView-office-name">{{ userData.hospitalsAssignedData[0].hospitalName }}</span>
        </v-col>
        <v-col cols="12" class="pl-0 mt-n2">
          <span class="staffView-office-address">{{userData.hospitalsAssignedData[0].medicalInstitutionData.address}}</span>
        </v-col>
        <v-col cols="12" class="pl-0 mt-n1">
        <span v-if="userData.hospitalsAssignedData[0].contactPerson" class="staffView-office-contact-person"><img :src="PatientBlack" class="staffView-office-contact-person-icon" />{{userData.hospitalsAssignedData[0].contactPerson}}</span>
          <div class="d-flex align-center justify-start">
            <v-icon color="primary" class="float-left mr-1" size="17">mdi-phone</v-icon>
            <span class="color-primary">
              {{userData.phone}}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row v-else dense class="my-5 mx-0 background-gray-color data-table-outline">
        <v-col cols="10" class="ml-4" >
          <span class="staffView-office-title">{{$t('assigned-offices')}}</span>
        </v-col>
        <v-col>
          <v-btn
              v-if="roleName === 'ORGANIZATION_ADMIN'"
              small
              icon
              elevation="0"
              color="secondary"
              @click="showDialogAssignHospitalToUser"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col class="px-0 py-0" cols="12">
          <v-simple-table dense class="background-gray-color">
            <template v-slot:default>
              <tbody>
              <tr class="px-0 pb-0" v-for="assignedHospital in userData.hospitalsAssignedData" :key="assignedHospital.id">
                <td class="text-left pl-5 font-bold">{{ assignedHospital.hospitalName }}</td>
                <td v-if="roleName === 'ORGANIZATION_ADMIN'" class="text-right">
                  <v-btn
                      v-if="roleName === 'ORGANIZATION_ADMIN'"
                      small
                      icon
                      elevation="0"
                      color="white"
                      @click="deAssignHospital(assignedHospital)"
                  >
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <AssignHospitalToUserDialog
        v-if="assignHospitalDialog"
        :dialog="assignHospitalDialog"
        :staffUser="userData"
        @false="assignHospitalDialog = false"
        @close="closeAssignHospitalDialog"
        @result="resultBackAssignedHospital"
    ></AssignHospitalToUserDialog>
  </v-card>
</template>

<script>
import {
  patientBlack,
	doctorBlack,
	nurseBlack,
} from '@/assets';
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import AssignHospitalToUserDialog from '@/views/dialogs/AssignHospitalToUserDialog';

export default {
	name: 'DashboardCoreAppBar',
	components: { AssignHospitalToUserDialog },
	data: () => ({
    PatientBlack: patientBlack,
		nurseBlack: nurseBlack,
		doctorBlack: doctorBlack,
		assignHospitalDialog: false,
	}),
	computed: {
		...mapState({
			// getUserById: (state) => state.detailedUser,
			// relatedUsers: (state) => state.hospitals.users,
			// medicalStaff: (state) => state.hospitals.medicalStaff,
			authUserData: (state) => state.authentication.userData,
			// relatedOrganizationMedicalStaff: (state) => state.medicalInstitutions.relatedOrganizationMedicalStaff,
		}),
		...mapGetters({
			getUserById: 'users/getUserById',
			roleName: 'authentication/getRole',
		}),
		userData () {
			return this.getUserById ? this.getUserById : null;
		},
		userRole () {
			return this.userData.roleData ? this.userData.roleData.roleNameForDisplay : '';
		},
		pathname () {
			return this.$route.name;
		},
		updateDrawer () {
			return this.$store.state.updateDrawer;
		},
	},
  methods: {
		async deAssignHospital (hospitalAssignedToDeassign) {
			const body = { HospitalId: hospitalAssignedToDeassign.id, UserId: this.userData.id };
			await this.$store.dispatch('hospitals/deassignUserFromHospital', body)
				.then(async (res) => {
					if (res.resFlag) {
						showSuccessAlert(this.$t('success'));

						// remove from local array of assigned hospitals
						const indexOfHospitalToremove = this.userData.hospitalsAssignedData.findIndex(object => {
							return object.id === hospitalAssignedToDeassign.id;
						});
						if (indexOfHospitalToremove > -1) {
							this.userData.hospitalsAssignedData.splice(indexOfHospitalToremove, 1);
						}
					} else {
						showErrorAlert(this.$t('failed'));
					}
				});
		},
		showDialogAssignHospitalToUser () {
			this.assignHospitalDialog = true;
		},
		resultBackAssignedHospital (res) {
			if (res.resFlag) {
				showSuccessAlert(this.$t('success'));

				// add element to local array
				const newElement = { id: res.data.hospitalId, hospitalName: res.hospitalName };
				this.userData.hospitalsAssignedData.push(newElement);

				this.closeAssignHospitalDialog();
			} else {
				showErrorAlert(this.$t('failed'));
			}
		},
		closeAssignHospitalDialog () {
			this.assignHospitalDialog = false;
		},
		phone (newVal) {
			if (newVal !== undefined) {
				if (!isValidPhoneNumber(newVal)) {
					this.phoneRules = [false];
				} else {
					this.phoneRules = [true];
				}
				return new AsYouType().input(newVal);
			}
		},
		closeEditDrowerDetails () {
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
    sinhronizeDrawers () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'medicalstaff',
      });
    },
	},
};
</script>
