<template>
    <v-navigation-drawer
        v-if="viewDetailsDrawer"
        right
        app
        :width="480"
    >
		<StaffView v-if="$route.path === '/medicalstaff'"/>
		<WellnessAssessmentsView v-if="$route.path === '/wellnessassessments'"/>
		<VerifierView v-if="$route.path ==='/verifier'"></VerifierView>
		<MedicalInstitutionView v-if="$route.path ==='/organizations'"></MedicalInstitutionView>
		<HospitalView v-if="$route.path ==='/offices'"></HospitalView>
		<HospitalAdminView v-if="$route.path ==='/admins'"></HospitalAdminView>
		<MonitoringView v-if="$route.path==='/monitoring'"></MonitoringView>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StaffView from '@/views/DrawerDialogs/StaffView.vue';
import WellnessAssessmentsView from '@/views/DrawerDialogs/WellnessAssessmentsView.vue';
import VerifierView from '@/views/DrawerDialogs/VerifierView.vue';
import MedicalInstitutionView from '@/views/DrawerDialogs/MedicalInstitutionView';
import HospitalView from '@/views/DrawerDialogs/HospitalView';
import HospitalAdminView from '@/views/DrawerDialogs/HospitalAdminView';
import MonitoringView from '@/views/DrawerDialogs/MonitoringView';

export default {
	components: {
		WellnessAssessmentsView,
		StaffView,
		VerifierView,
		MedicalInstitutionView,
		HospitalView,
		HospitalAdminView,
    MonitoringView,
	},
	data () {
		return {
			showDrawer: true,
			items: [
				{ title: 'Home', icon: 'mdi-view-dashboard' },
				{ title: 'About', icon: 'mdi-forum' },
			],
		};
	},
	computed: {
		...mapState(['barColor', 'barImage', 'webrtcErrors', 'pageSize']),
		...mapGetters({
			pageWidth: 'pageHeight',
		}),
		viewDetailsDrawer: {
			get () {
				return this.$store.state.viewDetailsDrawer;
			},
		// set (val) {
		// this.$store.commit('SET_EDIT_DETAILS_DRAWER', [val, 'ViewDrawer']);
		// },
		},
	},
};
</script>
