<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="selectedMedicalInstitution !== null">
    <v-card-title class="card-header" id="fixed-card-title">
      <v-row class="justify-end d-flex" dense>
        {{$t('viewOrganization')}}
        <v-spacer></v-spacer>
        <v-icon @click="synchronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
        <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
      </v-row>
    </v-card-title>
    <v-container class="">
      <v-card-text class="pt-2">
        <v-row dense class="pt-0 ">
          <v-col class="text-left pl-0" cols="9">
            <span class="view-organization">{{ selectedMedicalInstitution?.institutionName }}</span>
          </v-col>
          <v-col class="justify-end align-center d-flex" cols="3">
            <v-icon small :class="{'green-icon': 1 === 1}">mdi-checkbox-blank-circle</v-icon>
            <!--            FIXME-->
            <span class="green-icon">{{this.$t('active-caps')}}</span>
          </v-col>
          <v-col class="text-left" cols="12">
            <span class="darkgrey">{{ selectedMedicalInstitution?.address }}</span>
          </v-col>
        </v-row>
        <v-btn @click="getOfficesForThisMedicalInstitution" block outlined depressed class="my-5">
          <img :src="hospitalBlack" height="25px">
          <span class="capitalize-text">{{$t('view-offices')}}</span>
        </v-btn>
        <div v-if="showHospitals" class="view-offices-border">
          <v-list dense class="my-0 py-0 rounded-sm text-left">
            <v-list-item v-for="(item, index) in relatedHospitals" :key="item.id" :class="{'showhospitals-border-bottom': index !== relatedHospitals.length - 1 }">
              <v-list-item-title>
                {{ item.hospitalName }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <v-row dense class="data-table-outline my-4 text-left border-radius4">
          <v-col cols="12">
            <span class="view-organization-font">{{$t('description')}}</span>
          </v-col>
          <v-col cols="12">
            <span class="hospital-font">{{ selectedMedicalInstitution?.description }}</span>
          </v-col>
        </v-row>
        <v-row dense class="data-table-outline my-4 pa-2 text-left">
          <v-col class="text-left font-bold py-2" cols="12">
            <span class="contact-organization">{{$t('contact-person')}}</span>
          </v-col>
          <v-col class="text-left pt-0 pb-0" cols="12">
            <span class="pb-0 font-bold">{{ selectedMedicalInstitution?.contactPerson }}</span>
          </v-col>
          <v-col class="text-left align-center d-flex py-0" cols="12">
            <v-icon small class="mr-2 color-primary">mdi-email</v-icon>
            <span class="color-primary pl-0">{{ selectedMedicalInstitution?.contactEmail }}</span>
          </v-col>
          <v-col class="text-left align-center pt-0 d-flex" cols="12">
            <v-icon small class="mr-2 color-primary">mdi-phone</v-icon>
            <span class="color-primary pl-0">{{ selectedMedicalInstitution?.contactPhone }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hospitalBlack } from '@/assets';

export default {
  name: 'DashboardCoreAppBar',
  components: {
  },
  data: () => ({
    hospitalBlack: hospitalBlack,
    relatedHospitals: [],
    showHospitals: false,
  }),
  computed: {
    ...mapGetters({
      selectedMedicalInstitution: 'medicalInstitutions/getSelectedMedicalInstitution',
      detailedMedicalInstitution: 'medicalInstitutions/getDetailedMedicalInstitution',
    }),
  },
  methods: {
    ...mapActions({
      getMedicalInstitutionById: 'medicalInstitutions/getMedicalInstitutionById',
    }),
    closeDrawer () {
      this.$store.commit('medicalInstitutions/SELECTED_MEDICAL_INSTITUTION');
      this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
    },
    synchronizeDrawers () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'medicalinstitution',
      });
    },
    async getOfficesForThisMedicalInstitution () {
      this.showHospitals = !this.showHospitals;
      if (this.showHospitals) {
        await this.getMedicalInstitutionById(this.selectedMedicalInstitution.id);
        this.relatedHospitals = this.detailedMedicalInstitution.hospitalsData;
      }
    },
  },
  beforeDestroy () {
    this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
  },
};
</script>
