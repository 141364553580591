<template>
  <v-card
      class="mx-auto"
      elevation="0"
      v-if="surveyData !== null"
  >
    <v-card-title class="card-header fixed-card-title2">
      <v-row dense>
        <v-col cols="10" class="d-flex align-center justify-start">
          <!--          MENTAL_HEALTH_CATEGORY-->
          <v-toolbar-title >{{$t("View-Assessment")}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-icon left @click="sinhronizeDrawers" color="white" class="camel-case custom-outlined-color pr-1">mdi-pencil</v-icon>
          <v-icon color="white" height="36" @click="closeEditDrowerDetails"  class="custom-outlined-color ml-n2">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <!--        name of the test-->
      <div class="mt-5 mb-8">
        <v-row>
        <v-col cols="9" class="text-left">
          <span class="font-weight-bold font-size19 color-black">{{ surveyData.templateName }}</span>
        </v-col>
        <v-col cols="3" class="text-right">
          <span class="me-2" :style="surveyData.status ===1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold; font-size: 0.8em'" >
            <v-icon small :style="surveyData.status ===1 ? 'color: #52C41A; font-weight: bold' : 'color: gray; font-weight: bold'" class="me-2">mdi-checkbox-blank-circle</v-icon>
            {{ surveyData.status ===1 ? $t('active-caps') : $t('inactive-caps')}}
          </span>
        </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="wellness-assessment-desc-border mx-2 rounded" >
          <span class="wellness-desc mb-2">{{$t('description')}}</span>
          <span class="wellness-detail-desc">{{surveyData.templateDescription}}</span>
        </v-col>
      </v-row>
      <v-card class="overflow-auto mt-6"  elevation="0" :height="testHeight">
        <v-row class="d-flex justify-center">
        </v-row>
        <div class="mt-10">
          <v-row class="mx-0" dense>
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index">
                <div v-if="index === 0" class="text-left ml-5 mt-5 pb-5">
                  <template  >
                    <span class="wellness-assessment-questions">{{ $t('questions') }}</span>
                  </template>
                </div>
                <v-expansion-panel-header class="d-flex justify-space-between px-2 py-1 font-weight-bold font-size14">
                  <v-col cols="1">
                    <span><v-avatar size="26" color="chips" class="text-color-white">{{ index + 1 }}</v-avatar></span>
                  </v-col>
                  <v-col cols="11" class="">
                    <span class="line-height1-2"> {{ question.question }}</span>
                  </v-col>
                  <template v-slot:actions>
                    <v-icon color="primary"></v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-left" v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                  <v-icon v-if="answer.possibleAnswer.trim() !== ''">mdi-chevron-right</v-icon>
                  <span class="font-weight-bold">
                    {{ answer.possibleAnswer }}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'DashboardCoreAppBar',
	components: {
	},
	data () {
		return {
		};
	},
	computed: {
		...mapState({
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.survey,
		}),
		surveyData () {
			// return this.surveyById ? this.surveyById : null;
      return this.surveyById ? this.surveyById : {};
		},
		updateDrawer () {
			return this.$store.state.updateDrawer;
		},
    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '535';
      }
      return '900';
    },
	},
  mounted () {
  },
	beforeDestroy () {
		// if the view drawer is open and we click on another component we close the view drawer!
		this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
	},
	methods: {
		closeEditDrowerDetails () {
      // clean up the current select survey template from the store
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);

			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
		sinhronizeDrawers () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'wellnessassessments',
      });
		},
	},
};
</script>
<style>
.v-navigation-drawer__content{
overflow:hidden;
}
</style>
